import React, { useEffect, useState } from 'react'
import querystring from 'querystring'
import { navigate, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Row from '../components/row'
import Hero from '../components/hero'
import Section from '../components/section'
import ScanPostcard from '../components/images/scan-postcard'

const ReceivePage = ({ location }: { location: Location }) => {
  const [{ user, loading, error }, setState] = useState({
    user: null,
    loading: true,
    error: null,
  })

  useEffect(() => {
    let { scannedOrders } = localStorage

    if (!scannedOrders) {
      scannedOrders = {}
    } else {
      scannedOrders = JSON.parse(scannedOrders)
    }

    let { id, order } = querystring.parse(location.search.replace(/^\?/, ''))

    if (Array.isArray(order)) {
      order = order[0]
    }

    if (order) {
      if (scannedOrders[order]) {
        setState({ user: scannedOrders[order], loading: false, error: null })
      } else {
        ;(async () => {
          try {
            const res = await fetch(
              `${process.env.GATSBY_API_URL}/user?${querystring.stringify({
                id,
                order,
              })}`
            )
            const json = await res.json()
            if (!res.ok) {
              throw new Error(json.message || json)
            }

            setState({ user: json, loading: false, error: null })

            scannedOrders[order] = json
            localStorage.scannedOrders = JSON.stringify(scannedOrders)
          } catch (err) {
            setState({ user: null, loading: false, error: err.message })
          }
        })()
      }
    } else {
      setState({ user: null, loading: false, error: 'no user' })
    }
  }, [location.search])

  return (
    <Layout>
      <SEO />
      <Hero
        onShowStories={() => navigate(`/stories${location.search}`)}
        withNav={false}
        title={
          loading
            ? 'Loading...'
            : user
            ? `You received a postcard from ${user.name} 🎉`
            : error
        }
        content="Discover more content from them by installing the app."
      />
      <Section>
        <Row>
          <p>Install the Timelense application and scan the QR Code</p>
          <ScanPostcard />
        </Row>
        <Row>
          <img src="/TLscan-Square-small.gif" alt="View AR content" />
          <p>
            Timelense augments your postcard with all your friend's pictures and
            videos
          </p>
        </Row>
      </Section>
      <Section>
        <div>
          <h2>Not Using iOS?</h2>
          <p>
            Timelense is only available on iOS at the moment. Please register
            your interest{' '}
            <a href="https://mathieudutour673292.typeform.com/to/imotLy">
              here
            </a>{' '}
            so that we can better understand your priorities and get back to you
            when it's ready!
          </p>
          <p>
            You can also view the content without AR{' '}
            <Link to={`/stories${location.search}`}>here</Link>.
          </p>
        </div>
      </Section>
    </Layout>
  )
}

export default ReceivePage
